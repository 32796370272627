.delete-icon{
    color: white;
    padding: 3px;
    background-color: red;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.refresh-icon{
    color: white;
    padding: 3px;
    background-color: #1a73e8;
    border-radius: 10px;
}