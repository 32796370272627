.avatar-list {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    align-items: center;
    gap: 10px;
  }

.avatar-container {
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    max-width: 150px;
  }
  
  .avatar {
    width: 100px;
    height: 100px;
  }
  
  .selected {
    opacity: 0.7; /* Adjust the opacity to your preference */
    border: 2px solid #007bff; /* Add a border style to the selected avatar */
    border-radius: 5px;
  }
  