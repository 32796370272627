.delete-icon{
    color: white;
    padding: 5px;
    background-color: red;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.refresh-icon{
    color: white;
    padding: 5px;
    background-color: #1a73e8;
    border-radius: 10px;
}

.edit-icon {
    background-color: blue;
    border-radius: 10px;
    padding: 2px;
    color: white;
    width: 30px;
    height: 30px;
}

.delete-icon {
    background-color: red;
    border-radius: 10px;
    padding: 1px;
    color: white;
}

.article-action{
    margin-left: 20px;
}
